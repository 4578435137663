.sidebar {
  /*
  background: url(/public/images/sidebar-bg.jpg) no-repeat top center;
  background-size: cover;*/
  background-color: #222 !important;
  color: rgba(255, 255, 255, .75);

  .img-profile {
    border-radius: 50%;
    border: solid 3px white;
    margin: 0px auto 2rem;
  }

  .sidebar-about {
    h1 {
        font-size: 2rem;
    }

    .lead {
        font-size: 1rem;
    }
  }

  .sidebar-cta {
    margin: 1em 0;

    a {
      background-color: darkorange;
      font-size: 14px;
      padding: 5px 10px;
      border-radius: 5px;

      &:hover {
        text-decoration: none;
      }

      img {
        display: inline;
        margin: 0;
        vertical-align: middle;
      }
    }
  }

  .sidebar-cta-twitter {
    a {
      background-color: #1b95e0;
    }
  }
}

.post-subtitle {
  color: rgba(0, 0, 0, 0.44);
  font-size: 1.2rem;
  font-style: italic;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}

.posts {
  .post {
    border-bottom: 3px double lightgray;
    margin-bottom: 3em;
    padding-bottom: 2em;
  }
}

.investments {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 80px 40px;
  justify-content: space-between;
  margin-top: 40px;

  .investment {
    text-align: center;

    a:hover {
      text-decoration: none;
    }

    img {
      border-radius: 50%;
      display: block;
      margin: auto;
      height: 200px;
      width: 200px;
    }
  }
}

/* MailChimp Form Embed Code - Horizontal Super Slim - 12/16/2015 v10.7
Adapted from: http://blog.heyimcat.com/universal-signup-form/ */

#mc_embed_signup {
  border: 3px double lightgray;
  background-color: #f5f5f5;
  padding: 40px;

  p {
    color: #9a9a9a;
    font-style: italic;
  }

  .mc-field-group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
    justify-content: space-between;
  }

  input[type=email] {
    font-size: 20px;
    border: 1px solid lightgray;
    background-color: #fff;
    padding: 0.5em;
    height: 50px;
  }

  input[type=submit] {
    border: 0;
    border-radius: 3px;
    background-color: #6a9fb5;
    color: white;
    font-size: 20px;
    height: 50px;

    &:hover {
      background-color: darken(#6a9fb5, 20%);
      cursor: pointer;
    }
  }

  .button:hover {
    background-color: #777;
    cursor: pointer;
  }
}

.jekyll-twitter-plugin {
    margin-bottom: 1rem;
}

.content {
  padding-top: 2rem;
}
